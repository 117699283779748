import React, { useMemo } from 'react';
import styles from './AdminSidebar.module.scss';
import SidebarGroup, { SidebarSection } from './SidebarGroup/SidebarGroup';
import { routes } from '../../../../config/routes/routes';
import Speed from '@mui/icons-material/Speed';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import AccountBox from '@mui/icons-material/AccountBox';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../../atoms/translation/translation.atom';
import { translate } from '../../../../utils/translation/translation';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import logo from '../../../../assets/logo.png';
import cx from 'classnames';
import { motion } from 'framer-motion';
import { sidebarExtendedState } from '../../../../atoms/layout/layout.atom';
import { useWindowWidth } from '@react-hook/window-size';
import UserInfo from './UserInfo/UserInfo';
import { colors } from '../../../../config/styles/colors';
import { apiRoutes } from '../../../../config/api/apiRoutes';
import { roleAccessState } from '../../../../atoms/auth/auth.atom';
import { HttpMethod } from '../../../../domain/Role';
import CategoryIcon from '@mui/icons-material/Category';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import FactoryIcon from '@mui/icons-material/Factory';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import ArticleIcon from '@mui/icons-material/Article';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { companyState } from '../../../../atoms/company/company.atom';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PercentIcon from '@mui/icons-material/Percent';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import QuestionIcon from '@mui/icons-material/QuestionMark';
import ProductQuantityLimitIcon from '@mui/icons-material/ProductionQuantityLimits';

export const AdminSidebar = () => {
  const translations = useRecoilValue(translationsState);
  const sidebarExtendedValue = useRecoilValue(sidebarExtendedState);
  const roleAccessValue = useRecoilValue(roleAccessState);
  const companyStateValue = useRecoilValue(companyState);
  const screenWidth = useWindowWidth();

  const isCollapsed = useMemo(() => {
    if (screenWidth < 1200) {
      return false;
    }

    return !sidebarExtendedValue;
  }, [sidebarExtendedValue, screenWidth]);

  const sidebarWidth = useMemo(() => {
    if (screenWidth < 1200) {
      return isCollapsed ? 88 : 320;
    }
    return isCollapsed ? 88 : 280;
  }, [isCollapsed, screenWidth]);

  const isCompanySelected = useMemo(() => {
    if (process.env.REACT_APP_PLATFORM_ENABLED !== 'true') {
      return false;
    }

    return !!companyStateValue;
  }, [companyStateValue]);

  const companySidebarItems: SidebarSection[] = useMemo(() => {
    if (!isCompanySelected) {
      return [];
    }

    return [];
  }, [isCompanySelected]);

  const sidebarItems: SidebarSection[] = useMemo(() => {
    return [
      ...companySidebarItems,
      {
        title: translate(translations, 'SIDEBAR.GROUP_GENERAL'),
        items: [
          {
            title: translate(translations, 'SIDEBAR.DASHBOARD'),
            icon: <Speed />,
            url: routes.admin.dashboard,
          },
          {
            title: translate(translations, 'SIDEBAR.ORDERS'),
            icon: <MonetizationOnIcon />,
            url: routes.admin.orders.list,
          },
          {
            title: translate(translations, 'SIDEBAR.RETURN_ORDERS'),
            icon: <ProductQuantityLimitIcon />,
            url: routes.admin.returnOrders.list,
          },
          {
            title: translate(translations, 'SIDEBAR.BASKETS'),
            icon: <ShoppingCartIcon />,
            url: routes.admin.baskets.list,
          },
        ],
      },
      {
        title: translate(translations, 'SIDEBAR.GROUP_INVENTORY'),
        items: [
          {
            title: translate(translations, 'SIDEBAR.PRODUCTS'),
            icon: <PrecisionManufacturingIcon />,
            url: routes.admin.products.list,
          },
          {
            title: translate(translations, 'SIDEBAR.CATEGORIES'),
            icon: <CategoryIcon />,
            url: routes.admin.categories.list,
          },
          {
            title: translate(translations, 'SIDEBAR.BRANDS'),
            icon: <WarehouseIcon />,
            url: routes.admin.brands.list,
          },
          {
            title: translate(translations, 'SIDEBAR.MANUFACTURERS'),
            icon: <FactoryIcon />,
            url: routes.admin.manufacturers.list,
          },
          {
            title: translate(translations, 'SIDEBAR.VEHICLES'),
            icon: <DirectionsCarIcon />,
            url: routes.admin.vehicles.list,
          },
        ],
      },
      {
        title: translate(translations, 'SIDEBAR.GROUP_CMS'),
        items: [
          {
            title: translate(translations, 'SIDEBAR.BLOGS'),
            icon: <ArticleIcon />,
            url: routes.admin.blogs.list,
          },
          {
            title: translate(translations, 'SIDEBAR.ARTICLE_CATEGORIES'),
            icon: <ArticleIcon />,
            url: routes.admin.articleCategories.list,
          },
          {
            title: translate(translations, 'SIDEBAR.STATIC_PAGES'),
            icon: <NewspaperIcon />,
            url: routes.admin.staticPages.list,
          },
          {
            title: translate(translations, 'SIDEBAR.FAQ'),
            icon: <QuestionIcon />,
            url: routes.admin.faq.list,
          },
        ],
      },
      {
        title: translate(translations, 'SIDEBAR.GROUP_SETTINGS'),
        items: [
          {
            title: translate(translations, 'SIDEBAR.VAT_RATES'),
            icon: <PercentIcon />,
            url: routes.admin.vatRates.list,
          },
          {
            title: translate(translations, 'SIDEBAR.USERS'),
            icon: <AccountBox />,
            url: routes.admin.users.list,
            apiUrl: apiRoutes.users.list,
          },
          {
            title: translate(translations, 'SIDEBAR.TRANSLATIONS'),
            icon: <SpellcheckIcon />,
            url: routes.admin.translations.list,
            apiUrl: apiRoutes.translations.list,
          },
        ],
      },
    ].map((sidebarSection: SidebarSection) => {
      const accessibleSidebarItems = sidebarSection.items.filter(
        (sidebarItem) => {
          if (sidebarItem.enabled === false) {
            return false;
          }

          if (!sidebarItem.apiUrl) {
            return true;
          }

          return roleAccessValue.some(
            (roleAccessItem) =>
              roleAccessItem.url === `/api/${sidebarItem.apiUrl}` &&
              roleAccessItem.method === HttpMethod.GET,
          );
        },
      );

      return { ...sidebarSection, items: accessibleSidebarItems };
    });
  }, [roleAccessValue, companySidebarItems]);

  return (
    <motion.div
      animate={{ width: sidebarWidth }}
      exit={{ width: 88 }}
      className={cx(styles.sidebarContainer, {
        [styles.collapsed]: isCollapsed,
      })}
      transition={{
        duration: 0.5,
        mass: 0.5,
        restDelta: 0.001,
        ease: [0, 0, 0.58, 1],
      }}
      style={{
        borderColor: colors.dividerBorderColor,
        backgroundColor: colors.backgroundColor,
      }}
    >
      <Box className={styles.sidebarContainerInner}>
        <Box data-simplebar className={styles.sidebarContainerScrollable}>
          <Link to={'/'} className={styles.logoContainer}>
            <img src={logo} />
          </Link>
          <nav className={styles.navigation}>
            {sidebarItems
              .filter((sidebarSection) => sidebarSection.items.length > 0)
              .map((sidebarSection) => (
                <SidebarGroup
                  key={sidebarSection.title}
                  sidebarSection={sidebarSection}
                />
              ))}
          </nav>
          <UserInfo />
          <Box className={styles.postNavigation} />
        </Box>
      </Box>
    </motion.div>
  );
};

export default AdminSidebar;
