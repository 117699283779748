import React, { useEffect, useMemo } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { LayoutFactory, PageLayoutType } from '../../layout/LayoutFactory';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { authTokenState, roleAccessState } from '../../../atoms/auth/auth.atom';
import { routes } from '../../../config/routes/routes';
import LazyLoad from './LazyLoading/LazyLoading';
import NotFoundPage from '../../pages/shared/error/NotFoundPage/NotFoundPage';
import { useMutation } from '@tanstack/react-query';
import axios, { SuccessResponse } from '../../../libraries/axios/axios';
import { MappedRoleAccess } from '../../../domain/Role';
import { DefaultError } from '@tanstack/query-core/build/modern/index';
import { apiRoutes } from '../../../config/api/apiRoutes';

/* eslint-disable new-cap */
const Dashboard = LazyLoad(
  () => import('../../pages/shared/admin/DashboardPage/DashboardPage'),
);
const UsersPage = LazyLoad(
  () => import('../../pages/shared/admin/User/UsersPage/UsersPage'),
);
const UserCreatePage = LazyLoad(
  () => import('../../pages/shared/admin/User/UserCreatePage/UserCreatePage'),
);
const UserEditPage = LazyLoad(
  () => import('../../pages/shared/admin/User/UserEditPage/UserEditPage'),
);
const TranslationsPage = LazyLoad(
  () =>
    import(
      '../../pages/shared/admin/Translation/TranslationsPage/TranslationsPage'
    ),
);
const ProfilePage = LazyLoad(
  () => import('../../pages/shared/admin/Profile/ProfilePage/ProfilePage'),
);
const SettingsPage = LazyLoad(
  () => import('../../pages/shared/admin/Settings/SettingsPage/SettingsPage'),
);

const LoginPage = LazyLoad(
  () => import('../../pages/shared/public/LoginPage/LoginPage'),
);
const PasswordResetPage = LazyLoad(
  () => import('../../pages/shared/public/PasswordResetPage/PasswordResetPage'),
);
const PasswordResetConfirmationPage = LazyLoad(
  () =>
    import(
      '../../pages/shared/public/PasswordResetConfirmationPage/PasswordResetConfirmationPage'
    ),
);
const RegistrationConfirmationPage = LazyLoad(
  () =>
    import(
      '../../pages/shared/public/RegistrationConfirmationPage/RegistrationConfirmationPage'
    ),
);
const DeletionConfirmationPage = LazyLoad(
  () =>
    import(
      '../../pages/shared/public/DeletionConfirmationPage/DeletionConfirmationPage'
    ),
);

const CategoriesPage = LazyLoad(
  () =>
    import('../../pages/custom/admin/Category/CategoriesPage/CategoriesPage'),
);
const ArticleCategoriesPage = LazyLoad(
  () =>
    import(
      '../../pages/custom/admin/ArticleCategory/ArticleCategoriesPage/ArticleCategoriesPage'
    ),
);
const ArticleCategoriesCreatePage = LazyLoad(
  () =>
    import(
      '../../pages/custom/admin/ArticleCategory/ArticleCategoryCreatePage/ArticleCategoryCreatePage'
    ),
);
const ArticleCategoriesEditPage = LazyLoad(
  () =>
    import(
      '../../pages/custom/admin/ArticleCategory/ArticleCategoryEditPage/ArticleCategoryEditPage'
    ),
);
const CategoryEditPage = LazyLoad(
  () =>
    import(
      '../../pages/custom/admin/Category/CategoryEditPage/CategoryEditPage'
    ),
);

const BrandsPage = LazyLoad(
  () => import('../../pages/custom/admin/Brand/BrandsPage/BrandsPage'),
);
const BrandEditPage = LazyLoad(
  () => import('../../pages/custom/admin/Brand/BrandEditPage/BrandEditPage'),
);

const ManufacturersPage = LazyLoad(
  () =>
    import(
      '../../pages/custom/admin/Manufacturer/ManufacturersPage/ManufacturersPage'
    ),
);
const ManufacturerEditPage = LazyLoad(
  () =>
    import(
      '../../pages/custom/admin/Manufacturer/ManufacturerEditPage/ManufacturerEditPage'
    ),
);

const VehiclesPage = LazyLoad(
  () => import('../../pages/custom/admin/Vehicle/VehiclesPage/VehiclesPage'),
);

const ProductsPage = LazyLoad(
  () => import('../../pages/custom/admin/Product/ProductsPage/ProductsPage'),
);
const ProductEditPage = LazyLoad(
  () =>
    import('../../pages/custom/admin/Product/ProductEditPage/ProductEditPage'),
);

const BlogsPage = LazyLoad(
  () => import('../../pages/custom/admin/Blog/BlogsPage/BlogsPage'),
);
const BlogCreatePage = LazyLoad(
  () => import('../../pages/custom/admin/Blog/BlogCreatePage/BlogCreatePage'),
);
const BlogEditPage = LazyLoad(
  () => import('../../pages/custom/admin/Blog/BlogEditPage/BlogEditPage'),
);

const StaticPagesPage = LazyLoad(
  () =>
    import(
      '../../pages/custom/admin/StaticPage/StaticPagesPage/StaticPagesPage'
    ),
);
const StaticPageEditPage = LazyLoad(
  () =>
    import(
      '../../pages/custom/admin/StaticPage/StaticPageEditPage/StaticPageEditPage'
    ),
);

const OrdersPage = LazyLoad(
  () => import('../../pages/custom/admin/Order/OrdersPage/OrdersPage'),
);

const VatRatesPage = LazyLoad(
  () => import('../../pages/custom/admin/VatRate/VatRatesPage/VatRatesPage'),
);
const VatRateEditPage = LazyLoad(
  () =>
    import('../../pages/custom/admin/VatRate/VatRateEditPage/VatRateEditPage'),
);

const BasketsPage = LazyLoad(
  () => import('../../pages/custom/admin/Basket/BasketsPage/BasketsPage'),
);

const FaqPage = LazyLoad(
  () => import('../../pages/custom/admin/Faq/FaqPage/FaqPage'),
);
const FaqCreatePage = LazyLoad(
  () => import('../../pages/custom/admin/Faq/FaqCreatePage/FaqCreatePage'),
);
const FaqEditPage = LazyLoad(
  () => import('../../pages/custom/admin/Faq/FaqEditPage/FaqEditPage'),
);

const ReturnOrdersPage = LazyLoad(
  () =>
    import(
      '../../pages/custom/admin/ReturnOrder/ReturnOrdersPage/ReturnOrdersPage'
    ),
);
const ReturnOrderCreatePage = LazyLoad(
  () =>
    import(
      '../../pages/custom/admin/ReturnOrder/ReturnOrderCreatePage/ReturnOrderCreatePage'
    ),
);
const ReturnOrderEditPage = LazyLoad(
  () =>
    import(
      '../../pages/custom/admin/ReturnOrder/ReturnOrderEditPage/ReturnOrderEditPage'
    ),
);
/* eslint-enable new-cap */

export const RouteProvider = () => {
  const authStateValue = useRecoilValue(authTokenState);
  const setRoleAccessStateValue = useSetRecoilState(roleAccessState);

  const { mutate: getMappedRoleAccess } = useMutation<
    SuccessResponse<MappedRoleAccess[]>,
    DefaultError
  >({
    mutationFn: () => axios.get(apiRoutes.roles.mappedAccess),
    onSuccess: (response) => {
      setRoleAccessStateValue(response.data);
    },
  });

  useEffect(() => {
    if (authStateValue) {
      getMappedRoleAccess();
    }
  }, [authStateValue]);

  const adminPages = useMemo(() => {
    if (authStateValue) {
      return (
        <>
          <Route path={routes.admin.dashboard} element={<Dashboard />} />
          <Route path={routes.homepage} element={<Dashboard />} />
          <Route path={routes.admin.users.list} element={<UsersPage />} />
          <Route path={routes.admin.users.new} element={<UserCreatePage />} />
          <Route path={routes.admin.users.edit} element={<UserEditPage />} />
          <Route path={routes.admin.profile} element={<ProfilePage />} />
          <Route path={routes.admin.settings} element={<SettingsPage />} />
          <Route
            path={routes.admin.translations.list}
            element={<TranslationsPage />}
          />
          <Route
            path={routes.admin.categories.list}
            element={<CategoriesPage />}
          />
          <Route
            path={routes.admin.articleCategories.list}
            element={<ArticleCategoriesPage />}
          />
          <Route
            path={routes.admin.articleCategories.new}
            element={<ArticleCategoriesCreatePage />}
          />
          <Route
            path={routes.admin.articleCategories.edit}
            element={<ArticleCategoriesEditPage />}
          />
          <Route
            path={routes.admin.categories.edit}
            element={<CategoryEditPage />}
          />
          <Route path={routes.admin.brands.list} element={<BrandsPage />} />
          <Route path={routes.admin.brands.edit} element={<BrandEditPage />} />
          <Route
            path={routes.admin.manufacturers.list}
            element={<ManufacturersPage />}
          />
          <Route
            path={routes.admin.manufacturers.edit}
            element={<ManufacturerEditPage />}
          />
          <Route path={routes.admin.vehicles.list} element={<VehiclesPage />} />
          <Route path={routes.admin.products.list} element={<ProductsPage />} />
          <Route
            path={routes.admin.products.edit}
            element={<ProductEditPage />}
          />
          <Route path={routes.admin.blogs.list} element={<BlogsPage />} />
          <Route path={routes.admin.blogs.new} element={<BlogCreatePage />} />
          <Route path={routes.admin.blogs.edit} element={<BlogEditPage />} />
          <Route
            path={routes.admin.staticPages.list}
            element={<StaticPagesPage />}
          />
          <Route
            path={routes.admin.staticPages.edit}
            element={<StaticPageEditPage />}
          />
          <Route path={routes.admin.orders.list} element={<OrdersPage />} />
          <Route path={routes.admin.vatRates.list} element={<VatRatesPage />} />
          <Route
            path={routes.admin.vatRates.edit}
            element={<VatRateEditPage />}
          />
          <Route path={routes.admin.baskets.list} element={<BasketsPage />} />
          <Route path={routes.admin.faq.list} element={<FaqPage />} />
          <Route path={routes.admin.faq.new} element={<FaqCreatePage />} />
          <Route path={routes.admin.faq.edit} element={<FaqEditPage />} />
          <Route
            path={routes.admin.returnOrders.list}
            element={<ReturnOrdersPage />}
          />
          <Route
            path={routes.admin.returnOrders.new}
            element={<ReturnOrderCreatePage />}
          />
          <Route
            path={routes.admin.returnOrders.edit}
            element={<ReturnOrderEditPage />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </>
      );
    }

    return <></>;
  }, [authStateValue]);

  const authPages = useMemo(() => {
    if (authStateValue) {
      return (
        <>
          <Route
            path={routes.auth.registrationConfirmation}
            element={<RegistrationConfirmationPage />}
          />
          <Route
            path={routes.auth.deletionConfirmation}
            element={<DeletionConfirmationPage />}
          />
        </>
      );
    }

    return (
      <>
        <Route
          path={routes.auth.registrationConfirmation}
          element={<RegistrationConfirmationPage />}
        />
        <Route
          path={routes.auth.deletionConfirmation}
          element={<DeletionConfirmationPage />}
        />
        <Route
          path={routes.auth.passwordReset}
          element={<PasswordResetPage />}
        />
        <Route
          path={routes.auth.passwordResetConfirmation}
          element={<PasswordResetConfirmationPage />}
        />
        <Route path={routes.auth.login} element={<LoginPage />} />
        <Route path={routes.homepage} element={<LoginPage />} />
        <Route path="*" element={<Navigate to={routes.auth.login} replace />} />
      </>
    );
  }, [authStateValue]);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LayoutFactory variant={PageLayoutType.admin} />}>
          {adminPages}
        </Route>
        <Route element={<LayoutFactory variant={PageLayoutType.auth} />}>
          {authPages}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RouteProvider;
