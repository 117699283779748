export const apiRoutes = {
  auth: {
    refreshToken: 'auth/refresh-token',
    email: {
      login: 'auth/email/login',
      registrationConfirmation: 'auth/email/registration-confirmation',
      passwordChange: 'auth/email/password-change',
      passwordResetRequest: 'auth/email/password-reset-request',
      passwordReset: 'auth/email/password-reset',
    },
    google: {
      login: 'auth/google/login',
    },
  },
  translations: {
    list: 'translations',
    update: 'translations/:id',
  },
  languages: {
    locale: 'languages/locale/:locale',
    options: 'languages/options',
  },
  users: {
    get: 'users/:id',
    me: 'users/me',
    list: 'users',
    create: 'users',
    update: 'users/:id',
    delete: 'users/:id',
    selfDeleteRequest: 'users/self-delete-request',
    selfDelete: 'users/self-delete/:token',
    bulkDelete: 'users/bulk-delete',
  },
  userTokens: {
    get: 'user-tokens/:token',
  },
  userSettings: {
    updateSelf: 'user-settings/update-self',
    getSelf: 'user-settings/get-self',
    getLayoutOptions: 'user-settings/options/layout',
  },
  profile: {
    updateSelf: 'profile/update-self',
    getSelf: 'profile/get-self',
  },
  roles: {
    options: 'roles/options',
    mappedAccess: 'roles/mapped-access',
  },
  assets: {
    getAsset: 'assets/:id',
    uploadImage: 'assets/upload-image',
    uploadPrivateFile: 'assets/upload-private-file',
    uploadPrivateFiles: 'assets/upload-private-files',
    getOwnedAsset: 'assets/owned/:id',
    downloadFile: 'assets/download-file/:id',
    getDownloadableFile: 'assets/get-downloadable-file/:id',
  },
  health: {
    version: 'health/version',
  },
  audit: {
    list: 'audit',
  },
  entityTranslations: {
    get: 'entity-translations/entity',
  },
  companies: {
    get: 'companies/:id',
    list: 'companies',
    options: 'companies/options',
    create: 'companies',
    update: 'companies/:id',
    delete: 'companies/:id',
  },
  categories: {
    get: 'categories/:id',
    list: 'categories',
    all: 'categories/all',
    update: 'categories/:id',
    updateHierarchy: 'categories/hierarchy',
    updateTranslations: 'categories/:id/translations',
    updateSeoTranslations: 'categories/:id/seo-translations',
  },
  articleCategories: {
    list: 'article-categories',
    get: 'article-categories/:id',
    all: 'article-categories/all',
    create: 'article-categories',
    update: 'article-categories/:id',
    delete: 'article-categories/:id',
    updateTranslations: 'article-categories/:id/translations',
    updateSeoTranslations: 'article-categories/:id/seo-translations',
    categoriesOptions: 'article-categories/categories-options',
  },
  brands: {
    get: 'brands/:id',
    list: 'brands',
    update: 'brands/:id',
    updateSeoTranslations: 'brands/:id/seo-translations',
  },
  manufacturers: {
    get: 'manufacturers/:id',
    list: 'manufacturers',
    update: 'manufacturers/:id',
  },
  vehicles: {
    list: 'vehicles',
    getVehiclesStatistics: 'vehicles/statistics',
  },
  products: {
    get: 'products/:id',
    getLatest: 'products/latest',
    getSoldProductsStatistics: 'products/sold-products-statistics',
    getImportedProductsStatistics: 'products/imported-products-statistics',
    list: 'products',
    update: 'products/:id',
    updateSeoTranslations: 'products/:id/seo-translations',
  },
  blogs: {
    list: 'blogs',
    get: 'blogs/:id',
    create: 'blogs',
    update: 'blogs/:id',
    updateTranslations: 'blogs/:id/translations',
    delete: 'blogs/:id',
  },
  staticPages: {
    list: 'static-pages',
    get: 'static-pages/:id',
    update: 'static-pages/:id',
    updateTranslations: 'static-pages/:id/translations',
  },
  orders: {
    list: 'orders',
    options: 'orders/options',
    getOrdersStatistics: 'orders/statistics',
  },
  orderItems: {
    options: 'order-items/options',
    optionsByOrder: 'order-items/options/:id',
  },
  vatRates: {
    get: 'vat-rates/:id',
    list: 'vat-rates',
    update: 'vat-rates/:id',
  },
  baskets: {
    list: 'baskets',
  },
  faq: {
    list: 'faq',
    get: 'faq/:id',
    create: 'faq',
    update: 'faq/:id',
    updateTranslations: 'faq/:id/translations',
    delete: 'faq/:id',
  },
  returnOrders: {
    list: 'return-orders',
    get: 'return-orders/:id',
    create: 'return-orders',
    update: 'return-orders/:id',
    delete: 'return-orders/:id',
  },
};
